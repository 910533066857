<template>
  <div class="companyWelcome-wrap">
    <div class="companyWelcome-container">
      <div class="companyWelcome-top">
        <img
          src="./images/companyRegister-logo.png"
          class="companyWelcome-logo"
        />
        <span class="companyWelcome-top-title">欢迎您使用智慧社区平台</span>
      </div>
      <div class="companyWelcome-bottom">
        <div class="set-each">
          <div class="set-each-left">
            <img src="./images/set-version.png" class="set-each-version" />
            <div class="set-each-title">免费版</div>
            <div class="set-each-text">升级享更多权益</div>
          </div>
          <div class="set-each-right">
            <div class="set-each-intro">
              <div>
                您当前使用的<span class="text-big">免费版</span
                >，可管理小区数为<span>{{ info.communityMaxNum }}</span
                >个，使用周期为<span>{{ info.termOfValidity }}</span
                >个月。
              </div>
              <div>若需更多应用，请联系客服：<span>95059777-553</span></div>
            </div>
            <div class="set-each-operate">
              <div class="set-each-use" @click="toLogin()">免费试用</div>
              <!-- <div class="set-each-update">立即升级</div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="companyWelcome-show-wrap">
        <div class="companyWelcome-show-each">
          <div class="companyWelcome-show-each-title">物业管理</div>
          <div class="companyWelcome-show-each-img-cover1">
            <img src="./images/1.png" class="companyWelcome-show-each-img" />
          </div>
        </div>
        <div class="companyWelcome-show-each">
          <div class="companyWelcome-show-each-title">生活服务</div>
          <div class="companyWelcome-show-each-img-cover">
            <img src="./images/2.jpg" class="companyWelcome-show-each-img" />
          </div>
        </div>
        <div class="companyWelcome-show-each">
          <div class="companyWelcome-show-each-title">社区治理</div>
          <div class="companyWelcome-show-each-img-cover">
            <img src="./images/3.jpg" class="companyWelcome-show-each-img" />
          </div>
        </div>
        <div class="companyWelcome-show-each">
          <div class="companyWelcome-show-each-title">园区运营</div>
          <div class="companyWelcome-show-each-img-cover2">
            <img src="./images/4.jpg" class="companyWelcome-show-each-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info: {},
    };
  },

  created() {
    this.regionConfigInfo();
  },
  computed: {},
  methods: {
    toLogin() {
      this.$router.push({
        name: "login",
      });
    },
    regionConfigInfo() {
      this.$axios
        .get(`${API_CONFIG.butlerBaseURL}regionConfig/regionConfigInfo`)
        .then((res) => {
          if (res.status === 100) {
            this.info = res.data;
          } else {
            this.$message(res.msg);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.companyWelcome-wrap {
  background: #f1f3f7;
  height: 100%;
  overflow-y: scroll;
  .companyWelcome-container {
    width: 1384px;
    margin: 0 auto;
    .companyWelcome-top {
      display: flex;
      align-items: center;
      padding: 32px 0;
      .companyWelcome-logo {
        width: 225px;
        height: 44px;
      }
      .companyWelcome-top-title {
        font-size: 16px;
        padding-left: 21px;
        color: #666666;
      }
    }
    .companyWelcome-bottom {
      background: #ffffff;
      border-radius: 4px;
      padding: 32px 132px;
      .set-each {
        background: #fafafa;
        padding: 22px;
        display: flex;
        .set-each-left {
          width: 380px;
          height: 138px;
          background: url("./images/set-bg.png") center center no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          .set-each-version {
            width: 122px;
            height: 122px;
            position: absolute;
            left: -26px;
            top: -29px;
          }
          .set-each-title {
            font-size: 28px;
            font-weight: bold;
            color: #747e86;
            letter-spacing: 6px;
          }
          .set-each-text {
            font-size: 16px;
            color: #80878c;
            letter-spacing: 1px;
          }
        }
        .set-each-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding-left: 32px;
          .set-each-intro {
            width: 100%;
            font-size: 18px;
            color: #666666;
            text-align: left;
            span {
              color: #333333;
              font-weight: bold;
              &.text-big {
                font-size: 20px;
              }
            }
          }
          .set-each-operate {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .set-each-use {
              font-size: 18px;
              color: #5d7987;
              cursor: pointer;
            }
            .set-each-update {
              width: 160px;
              height: 40px;
              line-height: 40px;
              background: #e3d0b3;
              border-radius: 2px;
              font-size: 16px;
              font-weight: bold;
              color: #9a7945;
              cursor: pointer;
            }
          }
        }
      }
    }

    .companyWelcome-show-wrap {
      padding: 0 0 100px;
      width: 100%;
      .companyWelcome-show-each {
        .companyWelcome-show-each-title {
          color: #076ce0;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          padding: 60px 0 30px;
        }
        .companyWelcome-show-each-img-cover {
          background: #fff;
          padding: 30px 40px 10px;
        }
        .companyWelcome-show-each-img-cover1 {
          background: #fff;
          padding: 20px;
        }
        .companyWelcome-show-each-img-cover2 {
          background: #fff;
          padding: 0px 40px 20px;
        }
        .companyWelcome-show-each-img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
