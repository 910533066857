var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "companyWelcome-wrap" }, [
    _c("div", { staticClass: "companyWelcome-container" }, [
      _vm._m(0),
      _c("div", { staticClass: "companyWelcome-bottom" }, [
        _c("div", { staticClass: "set-each" }, [
          _vm._m(1),
          _c("div", { staticClass: "set-each-right" }, [
            _c("div", { staticClass: "set-each-intro" }, [
              _c("div", [
                _vm._v("\n              您当前使用的"),
                _c("span", { staticClass: "text-big" }, [_vm._v("免费版")]),
                _vm._v("，可管理小区数为"),
                _c("span", [_vm._v(_vm._s(_vm.info.communityMaxNum))]),
                _vm._v("个，使用周期为"),
                _c("span", [_vm._v(_vm._s(_vm.info.termOfValidity))]),
                _vm._v("个月。\n            "),
              ]),
              _vm._m(2),
            ]),
            _c("div", { staticClass: "set-each-operate" }, [
              _c(
                "div",
                {
                  staticClass: "set-each-use",
                  on: {
                    click: function ($event) {
                      return _vm.toLogin()
                    },
                  },
                },
                [_vm._v("免费试用")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._m(3),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "companyWelcome-top" }, [
      _c("img", {
        staticClass: "companyWelcome-logo",
        attrs: { src: require("./images/companyRegister-logo.png") },
      }),
      _c("span", { staticClass: "companyWelcome-top-title" }, [
        _vm._v("欢迎您使用智慧社区平台"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "set-each-left" }, [
      _c("img", {
        staticClass: "set-each-version",
        attrs: { src: require("./images/set-version.png") },
      }),
      _c("div", { staticClass: "set-each-title" }, [_vm._v("免费版")]),
      _c("div", { staticClass: "set-each-text" }, [_vm._v("升级享更多权益")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v("若需更多应用，请联系客服："),
      _c("span", [_vm._v("95059777-553")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "companyWelcome-show-wrap" }, [
      _c("div", { staticClass: "companyWelcome-show-each" }, [
        _c("div", { staticClass: "companyWelcome-show-each-title" }, [
          _vm._v("物业管理"),
        ]),
        _c("div", { staticClass: "companyWelcome-show-each-img-cover1" }, [
          _c("img", {
            staticClass: "companyWelcome-show-each-img",
            attrs: { src: require("./images/1.png") },
          }),
        ]),
      ]),
      _c("div", { staticClass: "companyWelcome-show-each" }, [
        _c("div", { staticClass: "companyWelcome-show-each-title" }, [
          _vm._v("生活服务"),
        ]),
        _c("div", { staticClass: "companyWelcome-show-each-img-cover" }, [
          _c("img", {
            staticClass: "companyWelcome-show-each-img",
            attrs: { src: require("./images/2.jpg") },
          }),
        ]),
      ]),
      _c("div", { staticClass: "companyWelcome-show-each" }, [
        _c("div", { staticClass: "companyWelcome-show-each-title" }, [
          _vm._v("社区治理"),
        ]),
        _c("div", { staticClass: "companyWelcome-show-each-img-cover" }, [
          _c("img", {
            staticClass: "companyWelcome-show-each-img",
            attrs: { src: require("./images/3.jpg") },
          }),
        ]),
      ]),
      _c("div", { staticClass: "companyWelcome-show-each" }, [
        _c("div", { staticClass: "companyWelcome-show-each-title" }, [
          _vm._v("园区运营"),
        ]),
        _c("div", { staticClass: "companyWelcome-show-each-img-cover2" }, [
          _c("img", {
            staticClass: "companyWelcome-show-each-img",
            attrs: { src: require("./images/4.jpg") },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }